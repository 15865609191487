<template>
  <div class="pt-5" style="width: 700px; margin: 0 auto;">
    <div v-for="seo_text in collection.seo_texts" :key="seo_text" class="mb-5">
      <div class="flex items-center border-b border-gray-700 py-2">
        <input
          class="appearance-none bg-transparent dark:text-white border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
          type="text" placeholder="Titl" v-model="seo_text.headline" aria-label="Title">
      </div>
      <label for="title"
        class="block px-3 pt-2 text-sm text-left font-medium text-gray-700 dark:text-gray-500">Titel</label>
      <div class="w-full mb-4 mt-5 border border-gray-200 rounded-lg bg-gray-50 dark:bg-gray-700 dark:border-gray-600">
        <div class="px-4 py-2 bg-white rounded-t-lg dark:bg-gray-800">
          <label class="text-left dark:text-gray-500 dark:border-b dark:border-gray-700">
            Text</label>
          <textarea rows="4" v-model="seo_text.text.children[0].children[0].value"
            class="w-full h-40 mt-2 focus:outline-none px-0 text-sm text-gray-900 bg-white border-0 dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400"
            placeholder="Text.." required></textarea>
        </div>
      </div>
    </div>
  </div>
  <ModalsContainer />

</template>


<script>
import axios from 'axios';
// import { mapState } from 'vuex';

import { ModalsContainer, useModal } from 'vue-final-modal'
import ModalConfirmPlainCss from '@/components/modal/ModalConfirmPlainCss.vue'

var modal_content = ''
const { open, close } = useModal({
  component: ModalConfirmPlainCss,
  attrs: {
    title: 'Loading..',
    clickToClose: false,
    escapeToClose: false
  },
  slots: {
    default: modal_content,
  },
})

export default {
  data() {
    return {
      collection: {
        seo_texts: []
      },
      tag_id: null,
    };
  },
  methods: {
    async fetchData() {
      const self = this;
      axios.get('api/shopify/collection-by-tag/' + this.tag_id + '/')
        .then(response => {
          self.collection = response.data;

          for (var i = 0; i < self.collection.seo_texts.length; i++) {
            self.collection.seo_texts[i].text = JSON.parse(self.collection.seo_texts[i].text);
            console.log(self.collection.seo_texts[i].text);
          }
          close()
        })
        .catch(error => {
          console.error("There was an error fetching the image count:", error);
          self.error = "Error fetching images";
        });
    }
  },
  components: {
    ModalsContainer
  },
  mounted() {
    this.tag_id = this.$route.params.id;
    this.fetchData();
    open()
  }
};
</script>

<style scoped>
.dashboard {
  padding: 20px;
  border-radius: 4px;
  text-align: center;
}

.error {
  color: red;
}
</style>