<template>
  <div class="flex pt-10">
    <div class="queue dark:[color-scheme:dark] overflow-y-auto"
      style="flex-basis: calc(100% - 350px); height: calc(100vh - 175px)">
      <p class="text-gray-300 mb-5">Available API Tokens: {{ available_api_tokens }}</p>
      <div style="" class="image_list ">
        <div v-for="image in images" :key="image.id" class="image">
          <div class="leonardo-image">
            <img style="max-width: 250px;" :src="config.mediaRoot + image.thumbnail" alt="Image" />
            <div class="icon-wrapper">
              <button @click="useImage(image.id)"><font-awesome-icon icon="check" /></button>
              <button @click="discardImage(image.id)"><font-awesome-icon icon="xmark" /></button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="height: calc(100vh - 125px); flex-basis: 350px; "
      class="queue overflow-y-auto dark:[color-scheme:dark] border-l border-1 dark:border-gray-500">
      <h3 class="mb-5 dark:text-gray-200">Queue</h3>

      <div v-for="task in task_queue" :key="task.id" class="image text-gray-400 pb-3">
        <div
          class="block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
          <div class="grid grid-cols-5">
            <div class="text-left" style="margin-top: -4px;"><span
                class="bg-gray-100 text-gray-800 mt-1 text-xs p-5 font-medium inline-flex items-center px-2.5 py-0.5 rounded me-2 dark:bg-gray-700 dark:text-gray-400 border border-gray-500 ">
                <font-awesome-icon icon="hashtag" />
                <span class="pl-1">{{ task.id }}</span>
              </span></div>
            <div class="text-center col-span-3" style="margin-top: -2px;">
              <span v-if="task.status == 'error'"
                class="bg-gray-100 text-red-800 text-xs p-5 font-medium inline-flex items-center px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-red-400 border border-red-500">ERROR</span>
              <span v-if="task.status == 'pending'"
                class="bg-gray-100 text-gray-800 text-xs p-5 font-medium inline-flex items-center px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-400 border border-gray-500">PENDING</span>
              <span v-if="task.status == 'processing'"
                class="bg-gray-100 text-blue-800 text-xs p-5 font-medium inline-flex items-center px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-blue-400 border border-blue-500">PROCESSING</span>
            </div>
            <div class="text-right">
              <div><span
                  class="bg-gray-100 text-gray-800 text-xs p-5 font-medium inline-flex items-center px-2.5 py-0.5 rounded me-2 dark:bg-gray-700 dark:text-gray-400 border border-gray-500 ">
                  <font-awesome-icon icon="images" />
                  <span class="pl-1">{{ task.num_images }}</span>
                </span></div>
            </div>
          </div>
          <p class="text-gray-700 dark:text-gray-400 mt-3 text-center">{{ task.prompt }}</p>
          <p class="text-xs pt-3">{{ formatDate(task.date) }} - {{ task.model }}</p>
          <p class="text-xs pt-3"></p>
        </div>
      </div>
    </div>
  </div>


  <!-- Modal toggle -->
  <button id="open-modal" @click="modal_visible = true;"
    class="block text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
    type="button">
    <svg class="me-1 -ms-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd"
        d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clip-rule="evenodd">
      </path>
    </svg>
  </button>

  <!-- Main modal -->
  <div id="crud-modal" tabindex="-1" v-if="modal_visible"
    class="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
    <div class="relative p-4 w-full max-w-md max-h-full">
      <!-- Modal content -->
      <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
        <!-- Modal header -->
        <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
          <h3 class="text-lg font-semibold text-gray-900 dark:text-white">
            Generate new images
          </h3>
          <button type="button" @click="closeModal"
            class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
            data-modal-toggle="crud-modal">
            <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
            </svg>
            <span class="sr-only">Close modal</span>
          </button>
        </div>
        <!-- Modal body -->
        <form class="p-4 md:p-5">
          <div class="grid gap-4 mb-4 grid-cols-2" v-if="!modal_submit_loading">
            <div class="col-span-4">
              <ul class="flex flex-wrap text-sm font-medium text-center text-gray-500  dark:text-gray-400">
                <li class="me-2">
                  <input type="radio" name="tab" v-model="modal_type" id="modal_type_tab_single" value="single"
                    class="hidden" checked />
                  <label for="modal_type_tab_single" aria-current="page" class=""
                    :class="modal_type == 'single' ? 'inline-block p-4 text-blue-600 bg-gray-100 rounded-t-lg dark:bg-gray-800 dark:text-blue-500' : 'inline-block p-4 rounded-t-lg hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300'">Single</label>
                </li>
                <li class="me-2">
                  <input type="radio" name="tab" v-model="modal_type" id="modal_type_tab_batch" value="batch"
                    class="hidden" />
                  <label for="modal_type_tab_batch" href="#" class=""
                    :class="modal_type == 'batch' ? 'inline-block p-4 text-blue-600 bg-gray-100 rounded-t-lg dark:bg-gray-800 dark:text-blue-500' : 'inline-block p-4 rounded-t-lg hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300'">Batch</label>
                </li>
              </ul>
              <div class="rounded-lg bg-gray-50 p-4 dark:bg-gray-800" style="margin-top: -3px;">
                <div role="tabpanel" :class="modal_type == 'single' ? 'block' : 'hidden'"
                  aria-labelledby="profile-tab-example">
                  <label for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Prompt</label>
                  <input v-model="modal_prompt" type="text" name="name" id="name"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="Type prompt" required="">
                </div>
                <div role="tabpanel" :class="modal_type == 'batch' ? 'block' : 'hidden'"
                  aria-labelledby="profile-tab-example">
                  <label for="model" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Select a
                    tag</label>
                  <select v-model="modal_batch_tag"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    id="model">
                    <option v-for="tag in tags" :key="tag.id" :value="tag.name">{{ tag.name }}</option>
                  </select>
                  <br />
                  <label for="promptCount" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Number
                    of
                    image sets/prompts: {{ modal_batch_prompt_count }}</label>
                  <input v-model="modal_batch_prompt_count" type="range" min="1" max="4" steps="1" id="promptCount" />
                  <br /><br />
                  <label class="inline-flex items-center cursor-pointer">
                    <input type="checkbox" v-model="modal_batch_shuffle_model" class="sr-only peer">
                    <div
                      class="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:w-5 after:h-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600">
                    </div>
                    <span class="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">Shuffle model?</span>
                  </label>
                </div>
                <div class="col-span-4" v-if="!modal_batch_shuffle_model">
                  <br />
                  <label for="model" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Select a
                    model</label>
                  <select v-model="modal_model"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    id="model">
                    <option v-for="model in models" :key="model.id" :value="model.id">{{ model.name }}</option>
                  </select>
                </div>
                <br />
                <div class="col-span-4">
                  <label for="imageCount" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Number of
                    images <span v-if="modal_type == 'batch'"> per set/prompt</span></label>
                  <input v-model="modal_image_count" type="range" min="1" max="4" steps="1" id="imageCount" />
                  <p class="dark:text-gray-400">{{ modal_image_count }}</p>
                </div>
              </div>
            </div>
          </div>

          <button v-if="!modal_submit_loading" type="submit" @click="submitModal"
            class="text-white inline-flex items-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
            Generate
          </button>
          <clip-loader v-if="modal_submit_loading" :loading="titleLoading" color="#4a5568" size="40px"
            class="ml-2"></clip-loader>
        </form>
      </div>
    </div>
  </div>

</template>

<script>
import axios from 'axios';
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
  data() {
    return {
      modal_visible: false,
      modal_prompt: "",
      modal_model: "",
      modal_type: "single",
      modal_image_count: 4,
      modal_submit_loading: false,
      modal_batch_shuffle_model: false,
      modal_batch_tag: null,
      modal_batch_prompt_count: 2,
      available_api_tokens: 0,
      task_queue: [],
      images: [],
      models: [],
      tags: [],
    };
  },
  methods: {
    async fetchData() {
      this.updateQueue();
      this.updateImageList();
      this.fetchApiTokens();
    },
    get_random(list) {
      return list[Math.floor((Math.random() * list.length))];
    },
    fetchApiTokens() {
      var self = this;
      axios.get('/api/generate-image/available-tokens/')
        .then(response => {
          self.available_api_tokens = response.data;
        })
        .catch(error => {
          console.error("There was an error fetching the image count:", error);
          self.error = "Error fetching images";
        });
    },
    async fetchTags() {
      axios.get('/api/shopify-tags/')
        .then(response => {
          this.tags = response.data;
          this.modal_batch_tag = response.data[0].name;
          console.log("Tags:", this.tags)
        })
        .catch(error => {
          console.error("There was an error fetching the image count:", error);
          self.error = "Error fetching images";
        });
    },
    async updateQueue() {
      var self = this;
      axios.get('/api/generate-image/all/')
        .then(response => {
          self.task_queue = response.data;
        })
        .catch(error => {
          console.error("There was an error fetching the image count:", error);
          self.error = "Error fetching images";
          self.isLoading = false;
        });
    },
    async updateImageList() {
      var self = this;
      axios.get('/api/generate-image/images/')
        .then(response => {
          if (self.images != response.data)
            self.images = response.data;
        })
        .catch(error => {
          console.error("There was an error fetching the image count:", error);
          self.error = "Error fetching images";
          self.isLoading = false;
        });
    },
    useImage(image_id) {
      axios.post('/api/generate-image/' + image_id + '/choice/')
        .then(() => {
          this.updateImageList();
        })
        .catch(error => {
          console.error("There was an error fetching the image count:", error);
          self.error = "Error fetching images";
          self.isLoading = false;
        });
    },
    discardImage(image_id) {
      axios.delete('/api/generate-image/' + image_id + '/choice/')
        .then(() => {
          this.updateImageList();
        })
        .catch(error => {
          console.error("There was an error fetching the image count:", error);
          self.error = "Error fetching images";
          self.isLoading = false;
        });
    },
    getModels() {
      var self = this;
      axios.get('/api/generate-image/models/')
        .then(response => {
          self.models = response.data;
          self.modal_model = response.data[0].id;
        })
        .catch(error => {
          console.error("There was an error fetching the image count:", error);
          self.error = "Error fetching images";
          self.isLoading = false;
        });
    },
    closeModal() {
      this.modal_visible = false;
      this.modal_prompt = "";
      this.modal_image_count = 4;
    },
    timeout(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    async submitModal() {
      console.log("Submit modal");
      this.modal_submit_loading = true;
      var self = this;

      if (this.modal_type == "batch") {
        for (var i = 0; i < this.modal_batch_prompt_count; i++) {
          var targetModel = this.modal_batch_shuffle_model ? this.get_random(this.models).id : this.modal_model;
          var response = await axios.post('api/openai/prompt-from-tag/', { "tag": self.modal_batch_tag })
          await axios.post('/api/generate-image/', {
            prompt: response.data.prompt,
            num_images: this.modal_image_count,
            model: targetModel
          })
        }
        self.modal_submit_loading = false;
        this.closeModal();
        this.updateQueue();
      } else {
        axios.post('/api/generate-image/', {
          prompt: this.modal_prompt,
          num_images: this.modal_image_count,
          model: this.modal_model
        })
          .then(() => {
            self.modal_submit_loading = false;

            this.closeModal();
            this.updateQueue();
          })
          .catch(error => {
            self.$root.showToast('Server error');
            console.error("There was an error fetching the image count:", error);
            self.error = "Error fetching images";
            self.modal_submit_loading = false;
          });
      }
    },
    formatDate(date) {
      return new Date(date).toLocaleString();
    }
  },
  mounted() {
    this.fetchData();
    this.getModels()
    this.fetchTags()

    setInterval(this.fetchData, 10000); // Update count every minute
  },
  components: {
    ClipLoader,
    FontAwesomeIcon
  },
};
</script>

<style scoped>
.dashboard {
  padding: 20px;
  border-radius: 4px;
  text-align: center;
}

.queue {
  padding: 20px;
  border-radius: 4px;
  text-align: center;
}

.image_list {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  text-align: center;
  flex-wrap: wrap;
}

.image_list>div {
  margin: 10px;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  font-size: 20px;
}

.image {
  margin-bottom: 20px;
}

.error {
  color: red;
}

#crud-modal {
  background: rgba(0, 0, 0, .5);
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
}

#crud-modal>div {
  width: 500px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}

#open-modal {
  border-radius: 100px;
  position: fixed;
  bottom: 25px;
  right: 25px;
  width: 52px;
  height: 52px;
}

.leonardo-image {
  position: relative;
  width: 100%;
  height: 100%;
}

.icon-wrapper:hover {
  background: rgba(0, 0, 0, .8);
}

.icon-wrapper {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  color: white;
  transition: .2s;
  font-size: 18pt;
}

.leonardo-image:hover .icon-wrapper {
  opacity: 1;
}

.leonardo-image .icon-wrapper button {
  /* border: 1px solid white; */
  padding-top: 3px;
  width: 40px;
  height: 40px;
  border-radius: 40px;
}
</style>

<style>
.v-spinner {
  align-items: center !important;
  margin: 0 auto !important;
  justify-content: center !important;
}

.v-spinner div {
  border-color: rgb(156 163 175) rgb(156 163 175) transparent !important;
}
</style>