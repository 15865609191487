<template>
    <a :href="getLinkWithParam()">
        <div class="flex flex-col items-center p-4 justify-center border-1  border border-solid border-gray-700 hover:border-gray-600 rounded-md"
            :class="$route.query.type == apiEndpoint ? 'bg-gray-600' : ''">
            <dt class="mb-2 text-3xl font-extrabold">{{ count }}</dt>
            <dd class="text-gray-500 dark:text-gray-400">{{ label }}</dd>
        </div>
    </a>
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            count: 0,
        };
    },
    props: {
        apiEndpoint: {
            type: String,
            default: ''
        },
        label: {
            type: String,
            default: 'Source Images Count'
        }
    },
    created() {
        this.fetchImageCount();
        setInterval(this.fetchImageCount, 10000); // Update count every minute
    },
    methods: {
        fetchImageCount() {
            axios.get('/api/source-image-count/' + this.apiEndpoint)
                .then(response => {
                    this.count = response.data.count;
                })
                .catch(error => {
                    console.error("There was an error fetching the image count:", error);
                });
        },
        getLinkWithParam() {
            return '/queue?type=' + this.apiEndpoint;
        }
    }
}
</script>

<style scoped>
.image-count-widget {
    padding: 10px;
    background-color: #f0f0f0;
    border-radius: 8px;
    text-align: center;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;

}
</style>