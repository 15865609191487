<template class="light">
  <div v-if="isLoading" class="bg-white" style="justify-content: center; align-items: center; display: flex; position: absolute; top: 0; left: 0; right: 0; bottom: 0; width: 100vw; height: 100vh; overflow: hidden;">
    <div class="flex items-center justify-center flex-wrap">
      <font-awesome-icon class="animate-spin" icon="spinner" />
      <p class="basis-full text-xs mt-2">{{ loadingText }}</p>
    </div>
  </div>
  <div v-if="!isLoading" class="bg-white" style="justify-content: center; align-items: center; display: flex; position: absolute; top: 0; left: 0; right: 0; bottom: 0; width: 100vw; height: 100vh; overflow: hidden;">
  <!-- <div class="flex align-center justify-center">
    
    <input class="m-auto" type="file" accept="image/*" @change="handleFileUpload">
  </div> -->

  <div v-if="upload_image_base64 && showingPreview">
    <img :src="preview_image_base64" :style="{
            width: image_data.orientation == 'portrait' ? image_data.width_frame_px + 'px' : image_data.height_frame_px + 'px', height: image_data.orientation == 'portrait' ? image_data.height_frame_px + 'px' : image_data.width_frame_px + 'px' }" />
  </div>

  <div v-if="!upload_image_base64 && !showingPreview" class="flex items-center justify-center w-64">
    <label for="dropzone-file" @drop.prevent="onDrop"
      class="flex flex-col items-center justify-center w-72 h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100">
      <div class="flex flex-col items-center justify-center p-8">
        <svg class="w-8 h-8 mb-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
          fill="none" viewBox="0 0 20 16">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
            d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
        </svg>
        <p class="mb-2 text-sm text-gray-500"><span class="font-semibold">Hier klicken, um Bild hochzuladen</span> (oder per drag and drop)</p>
        <p class="text-xs text-gray-500">PNG, JPG (MAX. 15MB)</p>
      </div>
      <input id="dropzone-file" @change="handleFileUpload" type="file" class="hidden" />
    </label>
  </div>

  <div v-if="upload_image_base64 && !showingPreview" class="preview-image-wrapper">
    <br />
    <div class="image-border-wrapper">
      <div class="image-preview-border border-2 border-solid "
        :class="currentResolutionFillsBorder == true ? 'border-gray-500' : 'border-red-500'"
        :style="{ width: image_data.orientation == 'portrait' ? image_data.width_frame_px + 'px' : image_data.height_frame_px + 'px', height: image_data.orientation == 'portrait' ? image_data.height_frame_px + 'px' : image_data.width_frame_px + 'px' }">
        <img class="preview-image" :src="upload_image_base64" :style="{
            width: image_data.scaled_width + 'px', height: image_data.scaled_height + 'px', top: image_data.top + 'px', left: image_data.left + 'px'
        }" @dragstart="startDragging">
      </div>
    </div>
  </div>
  <div  v-if="upload_image_base64 && showingPreview && !hideUi" style="position: absolute; bottom: 25px; left: 50%; transform: translateX(-50%)">
    <button @click=submitCrop class="button" type="button">
      in den Warenkorb
    </button>
  </div>

  <div class="top-button-wrapper"  v-if="upload_image_base64 && !showingPreview" style="position: absolute; display: flex; width: 75%; top: 10px; left: 50%; transform: translateX(-50%)">
    <button @click=previewCrop class="button" type="button">
      Vorschau
    </button>
    <button @click=returnToUpload class="button" type="button">
      Anderes Bild
    </button>
  </div>

  <div  v-if="upload_image_base64 && showingPreview && !hideUi" style="position: absolute; top: 10px; left: 10px;">
    <button @click=backToCrop class="button" type="button">
      Zurück
    </button>
  </div>

  <div v-if="upload_image_base64 && !showingPreview" style="position: absolute; width:100%; bottom: 20px; left: 50%; transform: translateX(-50%)">
        <ul class="grid mt-5 gap-1 md:grid-cols-3 w-3/4 orientation-toggles mx-auto items-center">
          <li class="flex" style="justify-content: end; padding-right: 20px;">
              <input @change="renderImage" type="radio" v-model="image_data.orientation" id="orientation-portrait" name="orientation" value="portrait" class="hidden peer" />
              <label for="orientation-portrait" class="inline-flex items-center justify-between p-3 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer peer-checked:border-gray-300 peer-checked:text-gray-100 hover:text-gray-400 hover:bg-gray-100">                           
              <font-awesome-icon icon="image-portrait" />
              </label>
          </li>
          <li style="margin-top: -25px;">
            <label class="dark:text-gray-400">Größe</label><br />
            <input id="size-slider" type="range" step=".1" min="1" max="300"
                  v-model="image_data.scale" v-on:change="renderImage"
                  v-on:input="renderImage"
                  class="w-64 h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer">
          </li>
          <li class="flex items-start" style="padding-left: 20px;">
              <input @change="renderImage" type="radio" v-model="image_data.orientation" id="orientation-landscape" name="orientation" value="landscape" class="hidden peer">
              <label for="orientation-landscape" class="inline-flex items-center justify-between p-3 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer peer-checked:border-gray-300 peer-checked:text-gray-100 hover:text-gray-400 hover:bg-gray-100">
              <font-awesome-icon icon="panorama" />
              </label>
          </li>
      </ul>
  </div>
  <div v-if="upload_image_base64 && !showingPreview" style="position: absolute; bottom: 20px; right: 25px; width: 150px;">
    <p class="variant-price">{{ variantPrice }}</p>
    <div class="formatSelectWrapper">
      <select @change="updateFrameFormat" v-model="selectedVariant" id="variant-select" class="select__select" name="options[Size]" form="product-form-template--21017605800263__main">
        <option value="200X200" selected="selected">
              20X20cm
            </option><option value="200X400">
              20X40cm
            </option><option value="200X250">
              20X25cm
            </option><option value="300X450">
              30X45cm
            </option><option value="200X300">
              20X30cm
            </option><option value="300X300">
              30X30cm
            </option><option value="300X400">
              30X40cm
            </option><option value="300X500">
              30X50cm
            </option><option value="350X400">
              35X40cm
            </option><option value="350X600">
              35X60cm
            </option><option value="350X350">
              35X35cm
            </option><option value="300X600">
              30X60cm
            </option><option value="400X400">
              40X40cm
            </option><option value="400X700">
              40X70cm
            </option><option value="400X450">
              40X45cm
            </option><option value="450X450">
              45X45cm
            </option><option value="400X600">
              40X60cm
            </option><option value="450X600">
              45X60cm
            </option><option value="500X500">
              50X50cm
            </option><option value="500X600">
              50X60cm
            </option><option value="500X700">
              50X70cm
            </option><option value="500X1000">
              50X100cm
            </option><option value="600X600">
              60X60cm
            </option><option value="600X700">
              60X70cm
            </option><option value="600X800">
              60X80cm
            </option><option value="600X1000">
              60X100cm
            </option><option value="600X900">
              60X90cm
            </option><option value="600X1200">
              60X120cm
            </option><option value="700X900">
              70X90cm
            </option><option value="700X1000">
              70X100cm
            </option><option value="800X1000">
              80X100cm
            </option><option value="800X1200">
              80X120cm
            </option>
      </select>
      <svg aria-hidden="true" focusable="false" class="icon icon-caret" viewBox="0 0 10 6">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.354.646a.5.5 0 00-.708 0L5 4.293 1.354.646a.5.5 0 00-.708.708l4 4a.5.5 0 00.708 0l4-4a.5.5 0 000-.708z" fill="currentColor">
      </path></svg>
    </div>
  </div>
</div>
</template>

<script>

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import axios from 'axios';
import { Buffer } from 'buffer';

export default {
  data() {
    return {
      hideUi: false,
      upload_image_base64: null,
      image_data: {
        top: 0,
        left: 0,
        width: 0,
        height: 0,
        scale: 100,
        prevScale: 100,
        max_height: 0,
        max_width: 0,
        orientation: null,
        scaled_height: 0,
        scaled_width: 0,
        width_frame_px: 0,
        height_frame_px: 0,
      },
      dragging: {
        initial_x: 0,
        initial_y: 0,
        initial_image_top: 0,
        initial_image_left: 0,
        active: false,
      },
      frame_width: 0,
      frame_height: 0,
      preview_scale: 5,
      isLoading: false,
      loadingText: "Loading...",
      showingPreview: false,
      preview_image_base64: null,
      formImage: null,
      priceData: null,
      variantPrice: null,
      selectedVariant: null,
      previewWrapperHeight: 0,
    };
  },
  computed: {
    // previewWrapperHeight() {
    //   // Get .preview-image-wrapper height
    //   return document.querySelector('.preview-image-wrapper').clientHeight;
    // },

    currentResolutionFillsBorder() {
        var x_valid = false;
        var y_valid = false;

        var target_width = this.image_data.orientation == "portrait" ? this.image_data.width_frame_px : this.image_data.height_frame_px;
        var target_height = this.image_data.orientation == "portrait" ? this.image_data.height_frame_px : this.image_data.width_frame_px;

        if (this.image_data.left <= 0) {
            x_valid = this.image_data.scaled_width + this.image_data.left >= target_width;
        } else {
            x_valid = false;
        }

        if (this.image_data.top <= 0) {
            y_valid = this.image_data.scaled_height + this.image_data.top >= target_height;
        } else {
            y_valid = false;
        }
        return x_valid && y_valid
    },
  },
  methods: {
    backToCrop() {
      this.showingPreview = false;
    },
    returnToUpload() {
      this.upload_image_base64 = null;
      this.showingPreview = false;
    },
    updateFrameFormat(e) {
      let size = e.target.value.split("X");
      this.frame_width = parseInt(size[0]);
      this.frame_height = parseInt(size[1]);
      this.variantPrice = this.priceData[this.frame_width / 10 + "X" + this.frame_height / 10 + "cm"];
      this.initialImageResize();
      this.renderImage();

      parent.postMessage({
        "action": "update_variant",
        "variant": this.frame_width / 10 + "X" + this.frame_height / 10 + "cm",
      }, "*");

    },
    previewCrop() {
      this.loadingText = "Diamond Painting Vorschau wird generiert. Bitte warten..\nBei größeren Formaten kann dies etwas länger dauern."
      this.isLoading = true;

      const formData = new FormData();
      formData.append("file", this.formImage);

      // Add scale and position data
      formData.append("scale", this.image_data.scale);
      formData.append("top", this.image_data.top * this.preview_scale);
      formData.append("left", this.image_data.left * this.preview_scale);

      // Add size data
      formData.append("width", this.image_data.width_frame_px * this.preview_scale);
      formData.append("height", this.image_data.height_frame_px * this.preview_scale);

      formData.append("width_mm", this.frame_width);
      formData.append("height_mm", this.frame_height);

      // Add orientation
      formData.append("orientation", this.image_data.orientation);
    
      // Add id
      formData.append("id", this.$route.params.id);
      const self = this;

      axios
        .post("api/shopify/shop-preview/", formData)
        .then((response) => {
          if(response.data.error == false) {
            let queryInterval = setInterval(() => {
              axios
                .get("api/shopify/shop-preview/" + self.$route.params.id + "/status/",  {responseType: 'arraybuffer'})
                .then((response) => {
                  // If response content type is image/jpeg
                  if(response.status == 200) {
                    clearInterval(queryInterval);
                    let base64_buffer = Buffer.from(response.data).toString('base64');
                    self.preview_image_base64 = "data:image/jpeg;base64," + base64_buffer;
                    self.isLoading = false;
                    self.showingPreview = true;
                  } else {
                    if (response.status == 500) {
                        clearInterval(queryInterval);
                        self.isLoading = false;
                        alert("An error occurred while generating the preview. Please try again.");
                    }
                  }
                })
                .catch(() => {
                  if (response.status == 500) {
                    clearInterval(queryInterval);
                    alert("An error occurred while generating the preview. Please try again.");
                    self.isLoading = false;
                  }
                });
            }, 2000);
          }
        })
    },
    submitCrop() {
      this.loadingText = "Diamond Painting wird generiert und dem Warenkorb hinzugefügt.."
      this.isLoading = true;
      this.hideUi = true;

      axios
        .post("api/shopify/shop-preview/" + this.$route.params.id + "/",)
        .then((response) => {
          let queryInterval = setInterval(() => {
            axios
              .get("api/shopify/shop-preview/" + this.$route.params.id + "/status/")
              .then((response) => {
                // If response content type is image/jpeg
                if(response.status == 200) {
                  clearInterval(queryInterval);
                  parent.postMessage({
                    "action": "submit_crop",
                    "image_url": response.data.url,
                    "preview_url": response.data.preview_url,
                  }, "*");
                } else {
                  if (response.status == 500) {
                      clearInterval(queryInterval);
                      self.isLoading = false;
                      alert("An error occurred while generating the preview. Please try again.");
                  }
                }
              })
              .catch(() => {
                if (response.status == 500) {
                  clearInterval(queryInterval);
                  alert("An error occurred while generating the preview. Please try again.");
                  self.isLoading = false;
                }
              });
          }, 2000);          
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      this.uploadLogic(file);
    },
    preventDefaults(e) {
      e.preventDefault()
    },
    onDrop(e) {
      this.uploadLogic(e.dataTransfer.files[0]);
    },
    uploadLogic(file) {
      if(file.type != "image/png" && file.type != "image/jpeg" && file.type != "image/jpg") {
        alert("Invalid file type. Please upload a PNG or JPG file.");
        return;
      }

      if(file.size > 15000000) {
        alert("File is too large. Please upload a file smaller than 15MB.");
        return;
      }

      const reader = new FileReader();
      const self = this;
      reader.onload = (e) => {
        self.upload_image_base64 = e.target.result;

        const img = new Image();
        img.src = e.target.result;
        img.onload = () => {
          self.image_data.width = img.width;
          self.image_data.height = img.height;
          self.image_data.orientation = (img.width > img.height) ? 'landscape' : 'portrait';
          self.previewWrapperHeight = document.querySelector('.preview-image-wrapper').clientHeight;

          self.initialImageResize()        

          self.renderImage();
        };


      };
      reader.readAsDataURL(file);
      this.formImage = file;
    },
    initialImageResize() {
      const self = this;
      self.renderImage();

      // Set scale so that both width and height are greater than frame width and height
      let scale = 100;
      var targetWidth, targetHeight;
      var newWidth = (self.image_data.width * (scale / 100)) / self.preview_scale;
      var newHeight = (self.image_data.height * (scale / 100)) / self.preview_scale;
      if(self.orientation == "portrait") {
        targetWidth = self.image_data.width_frame_px;
        targetHeight = self.image_data.height_frame_px;
      } else {
        targetWidth = self.image_data.height_frame_px;
        targetHeight = self.image_data.width_frame_px;
      }

      while(newWidth < targetWidth || newHeight < targetHeight) {
        scale += 1;
        newWidth = (self.image_data.width * (scale / 100)) / self.preview_scale;
        newHeight = (self.image_data.height * (scale / 100)) / self.preview_scale;
      }

      self.image_data.scale = scale;
      
      // Center image
      // self.image_data.top = (self.image_data.height_frame_px - self.image_data.scaled_height) / 2;
      // self.image_data.left = (self.image_data.width_frame_px - self.image_data.scaled_width) / 2;
      self.image_data.left = 0;
    },
    renderImage(e = null) {
      const self = this;

      if (e) {
        let scaleValid = self.changeScale(e);

        if (!scaleValid) {
          self.image_data.scale = self.image_data.prevScale;
          e.preventDefault();
          return;
        }
      }

      self.image_data.prevScale = self.image_data.scale;

      let frame_height_px = Math.round((self.frame_height * 72) / 25.4)

      console.log("Frame height: " + frame_height_px);
      console.log("Wrapper height: " + self.previewWrapperHeight);
      // if (self.frame_height > self.previewWrapperHeight) {
      let scale = frame_height_px / self.previewWrapperHeight;
      console.log("Scale: " + scale);
      self.preview_scale = scale;
      // }

      self.image_data.max_width = (self.image_data.width > self.image_data.height) ? self.image_data.width : self.image_data.height;
      self.image_data.max_height = (self.image_data.width > self.image_data.height) ? self.image_data.width : self.image_data.height;

      self.image_data.scaled_height = (self.image_data.height * (self.image_data.scale / 100)) / self.preview_scale;
      self.image_data.scaled_width = (self.image_data.width * (self.image_data.scale / 100)) / self.preview_scale;

      self.image_data.width_frame_px = Math.round((self.frame_width * 72) / 25.4) / self.preview_scale;
      self.image_data.height_frame_px = Math.round((self.frame_height * 72) / 25.4) / self.preview_scale;
    },
    changeScale() {
      const newWidth = (this.image_data.width * (this.image_data.scale / 100)) / this.preview_scale;
      const newHeight= (this.image_data.height * (this.image_data.scale / 100)) / this.preview_scale;
      const orientationWidth = this.image_data.orientation == "portrait" ? this.image_data.width_frame_px : this.image_data.height_frame_px;
      const orientationHeight = this.image_data.orientation == "portrait" ? this.image_data.height_frame_px : this.image_data.width_frame_px;
      // Check if the new scaled image width is less than frame width
      if (newWidth < orientationWidth) {
        return false
      }
      
      // Check if the new scaled image height is less than frame height
      if (newHeight < orientationHeight) {
        return false
      }

      this.image_data.scaled_width = newWidth;
      this.image_data.scaled_height = newHeight;
      // Check if the new scaled image fits within the frame bounds
      const xMin = (this.image_data.scaled_width - orientationWidth) * -1;
      const xMax = 0;
      const yMin = (this.image_data.scaled_height - orientationHeight) * -1;
      const yMax = 0;
      
      if (this.image_data.left < xMin) {
        this.image_data.left = xMin;
      } else if (this.image_data.left > xMax) {
        this.image_data.left = xMax;
      }
      
      if (this.image_data.top < yMin) {
        this.image_data.top = yMin;
      } else if (this.image_data.top > yMax) {
        this.image_data.top = yMax;
      }
      
      return true;
    },
    startDragging(e) {
        e.preventDefault();
        var self = this;
        this.dragging.active = true;

        this.dragging.initial_y = event.clientY;
        this.dragging.initial_x = event.clientX;

        this.dragging.initial_image_top = this.image_data.top;
        this.dragging.initial_image_left = this.image_data.left;

        window.addEventListener('mousemove', self.draggingHandler);
        window.addEventListener('mouseup', self.endDragging);
        // event.dataTransfer.setDragImage(this.dragImg, 0, 0);
    },
    handleDrop(e) {
        e.preventDefault();
        this.endDragging(e);
    },
    draggingHandler(e) {
      e.preventDefault();
      const offsetX = this.dragging.initial_image_left + (event.clientX - this.dragging.initial_x);
      const offsetY = this.dragging.initial_image_top + (event.clientY - this.dragging.initial_y);

      // Check if the new position is within the frame bounds
      const orientationWidth = this.image_data.orientation == "portrait" ? this.image_data.width_frame_px : this.image_data.height_frame_px;
      const xMin = (this.image_data.scaled_width - orientationWidth) * -1
      const xMax = 0;

      const orientationHeight = this.image_data.orientation == "portrait" ? this.image_data.height_frame_px : this.image_data.width_frame_px;
      const yMin = (this.image_data.scaled_height - orientationHeight) * -1
      const yMax = 0;
      
      if(offsetX <= xMax && offsetX >= xMin) {
        this.image_data.left = offsetX;
      }

      if(offsetY <= yMax && offsetY >= yMin) {
        this.image_data.top = offsetY;
      }
    },
    endDragging(e) {
      e.preventDefault();
      this.dragging.active = false;
      window.removeEventListener('mousemove', this.draggingHandler);
    }
  },
  mounted() {
    this.frame_width = 400
    this.frame_height = 600

    let $this = this;

    window.addEventListener("message", (e) => {
      if(e.data.action == "set_size") {
        let size = e.data.size.replace("cm", "").split("X");
        $this.frame_width = parseInt(size[0]) * 10;
        $this.frame_height = parseInt(size[1]) * 10;

        $this.variantPrice = e.data.price_data[$this.frame_width / 10 + "X" + $this.frame_height / 10 + "cm"];
        $this.selectedVariant = $this.frame_width + "X" + $this.frame_height;
        $this.priceData = e.data.price_data;
        $this.renderImage();
      }
    });

    parent.postMessage({
      "action": "page_loaded",
    }, "*");

    const events = ['dragenter', 'dragover', 'dragleave', 'drop']

    events.forEach((eventName) => {
        document.body.addEventListener(eventName, this.preventDefaults)
    })
  },
  beforeMount() {
    let crop_id = this.$route.params.id;
    this.$root.isShopifyView = true;
    if(!crop_id) {
      let random_uuid = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
      this.$router.push({ name: 'Shopify Upload', params: { id: random_uuid } });
      return
    }
  },
  beforeUnmount() { 
    const events = ['dragenter', 'dragover', 'dragleave', 'drop']

    events.forEach((eventName) => {
        document.body.removeEventListener(eventName, this.preventDefaults)
    })
  },
  components: {
    FontAwesomeIcon
  },
};
</script>

<style scoped>

.button {
  font-size: 24px;
  font-weight: 700;
  width: 100%;
  border-radius: 30px;
  padding: 5px 20px;
  margin: 5px 10px;
  text-underline-offset: .3rem;
  text-decoration-thickness: .1rem;
  transition: text-decoration-thickness var(--duration-short) ease;
  background: rgb(255, 120, 120);
  color: white;
  text-align: center;
}

.top-button-wrapper {
  justify-content: center;
}

.top-button-wrapper .button {
  font-size: 20px;
  padding: 3px 10px;
  width: 30%; 
}

.variant-price {
  color: #FF7878 !important;
  font-size: 28px !important;
  font-weight: 500 !important;
}

.orientation-toggles .inline-flex {
  color: rgb(255, 120, 120);
  border: 1px solid rgb(255, 120, 120);
}

.formatSelectWrapper {
  border: 2px solid rgb(255, 120, 120);
  padding: 5px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.formatSelectWrapper select {
  width: 100%;
  -webkit-appearance: none;
  appearance: none;
}

.formatSelectWrapper svg {
  width: 10px;
  height: 10px;
}

.peer:checked ~ .peer-checked\:text-gray-100 {
  background: rgb(255, 120, 120) !important;
  border: 1px solid rgb(255, 120, 120);
  color: white !important;
}

#size-slider {
  -webkit-appearance: none;
  width: 100%;
  height: 10px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

#size-slider:hover {
  opacity: 1;
}

#size-slider::-webkit-slider-thumb {
  background: rgb(255, 120, 120); /* Green background */
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 25px;
  cursor: pointer; /* Cursor on hover */
}

#size-slider::-moz-range-thumb {
  background: rgb(255, 120, 120); /* Green background */
  width: 15px;
  height: 15px;
  margin-top: -3px;
  border-radius: 15px;
  cursor: pointer; /* Cursor on hover */
}

.preview-image-wrapper {
    position: relative;
    margin: 0 auto;
    width: 100%;
    height: calc(100vh - 160px);
}

.image-preview-border {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 5;
    transform: translate(-50%, -50%);
    overflow: hidden;
    transform-origin: center;
}

.preview-entry-info {
    width: 500px;
    margin: 0 auto;
}

.preview-image {
    position: absolute;
    z-index: 4;
    cursor: move;
    max-width: none;
    /* Add cursor style for dragging */
}

.image-border-wrapper {
    /* display: flex; */
}</style>