import { createStore } from 'vuex';
import axios from "axios";

export default createStore({
    state: {
        // Define other state variables as needed
    },
    mutations: {
        // Mutations for updating state variables
    },
    actions: {
        // Example logout action; still needs to tell the server to end the session
        logout() {
            // You would need to call an API endpoint that will clear the cookie
            axios.post('/api/logout/')
                .then(() => {
                    // Handle successful logout, e.g., redirecting to login page
                    this.$router.replace({ name: 'Login' });
                })
                .catch(error => {
                    console.error('Logout failed:', error);
                });
        }
    },
    getters: {
        // Getters for accessing pieces of state
    }
});