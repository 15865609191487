<template>
    <div class="login-container">
        <h1>Logout</h1>

    </div>
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            credentials: {
                username: '',
                password: ''
            },
            error: null
        };
    },
    mounted() {
        this.logout();
    },
    methods: {
        async logout() {
            try {
                // Make the login request
                const response = await axios.post('/api/logout/', this.credentials, {
                    withCredentials: true  // Ensure cookies are sent with the request
                });

                // Check the HTTP status code
                if (response.status === 200) {
                    this.$root.isLoggedIn = false;
                    // Navigate to the dashboard on successful login
                    this.$router.push('/');
                } else {
                    // Throw an error if the response code is not what you expect
                    throw new Error('Logout was not successful');
                }
            } catch (e) {

                console.error('Logout error:', this.error);
            }
        }
    }
};
</script>

<style scoped>
.login-container {
    max-width: 300px;
    margin: auto;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.form-group {
    margin-bottom: 20px;
}

label {
    display: block;
    margin-bottom: 5px;
}

input[type="text"],
input[type="password"] {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
}
</style>