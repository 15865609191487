<template>
  <div v-if="!isLoading">
    <TabGroup as="div" class="dark:bg-gray-800" :selectedIndex="selectedTab" @change="changeTab">
      <div class="bg-white border-gray-300 dark:bg-gray-900 p-0 border-b border-1 border-solid dark:border-gray-700">
        <div class="max-w-screen-xl flex flex-wrap dark:bg-gray-900 items-center justify-end mx-auto">
          <TabList as="ul"
            class="text-sm dark:bg-gray-900 font-medium justify-center text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700 flex flex-wrap -mb-px">
            <Tab as="li" class="me-2 focus:outline-none focus-visible:outline-none" key="image_tab"
              v-slot="{ selected }">
              <a class="focus:outline-none focus-visible:outline-none"
                :class="!selected ? 'inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300' : 'inline-block p-4 text-blue-600 border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500'"
                href="#image">
                Image
              </a>
            </Tab>
            <Tab as="li" class="me-2 focus:outline-none focus-visible:outline-none" key="cropping_tab"
              v-slot="{ selected }">
              <a class="focus:outline-none focus-visible:outline-none"
                :class="!selected ? 'inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300' : 'inline-block p-4 text-blue-600 border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500'"
                href="#crop">
                Cropping
              </a>
            </Tab>
            <Tab as="li" class="me-2 focus:outline-none focus-visible:outline-none" key="copy_tab"
              v-slot="{ selected }">
              <a class="focus:outline-none focus-visible:outline-none"
                :class="!selected ? 'inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300' : 'inline-block p-4 text-blue-600 border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500'"
                href="#copy">
                Copy
              </a>
            </Tab>
          </TabList>
        </div>
      </div>
      <div style="position: absolute; top: 150px; left: 50%; transform: translateX(-50%);" class="dark:text-gray-300">
        <span>{{ image.image_index + 1 }} / {{ image.total_images }}</span>
      </div>
      <TabPanels class="mt-2">
        <TabPanel :class="[]">
          <div class="flex align-center justify-center" style="align-items: center; height: calc(100vh - 160px);">
            <div id="orig-image-wrapper">
              <img :src="config.mediaRoot + image.image" alt="Original Image" id="orig-image">
            </div>
            <div class="flex dark:text-gray-200 justify-start" style="margin: 0 auto; flex-wrap: wrap; width: 50vw;">
              <div style="flex-basis: 100%" class="grid grid-cols-3">
                <div>
                  <label class="inline-flex items-center cursor-pointer">
                    <input type="checkbox" v-model="image.active" @change="updateActiveStatus" class="sr-only peer">
                    <div
                      class="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:w-5 after:h-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600">
                    </div>
                    <span class="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">Enabled</span>
                  </label>
                </div>
                <div>
                  <label class="inline-flex items-center cursor-pointer">
                    <input type="checkbox" v-model="image.image_processing_done" @change="updateProcessingStatus"
                      class="sr-only peer">
                    <div
                      class="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:w-5 after:h-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600">
                    </div>
                    <span class="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">Image processed</span>
                  </label>
                </div>
                <div>
                  <label class="inline-flex items-center cursor-pointer">
                    <input type="checkbox" v-model="image.copy_approved" @change="updateCopyStatus"
                      class="sr-only peer">
                    <div
                      class="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:w-5 after:h-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600">
                    </div>
                    <span class="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">Copy approved</span>
                  </label>
                </div>
              </div>
              <h3 class="text-lg font-bold pb-5" style="flex-basis: 100%">
                Tags</h3>
              <br />
              <div class="flex border-1 border-l  border-gray-500 flex-wrap"
                style="height: calc(100vh - 400px); overflow-y: scroll; flex-basis: 100%;">
                <div style="width: 33.33%;"
                  class="tag-category-wrapper pb-2 border-solid border-1 border-gray-500 border-r "
                  v-for="tag in available_tags.filter(tag => top_level_tags.includes(tag.id) && !other_tags.includes(tag))"
                  :key="tag.id">
                  <div
                    class="flex pl-3 items-center mb-4 mt-0 py-3 border-solid border-1 border-gray-500 border-b border-t">
                    <input :id="'tag-checkbox_primary_' + tag.id" @change="updateTags" type="checkbox" :value="tag.id"
                      v-model="image.tags"
                      class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                    <label :for="'tag-checkbox_primary_' + tag.id"
                      class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">{{ tag.name }}</label>
                  </div>
                  <ul v-for="sub_tag in available_tags" :key="sub_tag.id">
                    <li v-if="sub_tag.parents.includes(tag.id)">
                      <div class="flex pl-3 items-center mb-1">
                        <input :id="'tag-checkbox_' + sub_tag.id" @change="updateTags" type="checkbox"
                          :value="sub_tag.id" v-model="image.tags"
                          class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                        <label :for="'tag-checkbox_' + sub_tag.id"
                          class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">{{ sub_tag.name }}</label>
                      </div>
                    </li>
                  </ul>
                </div>
                <div style="width: 33.33%;"
                  class="tag-category-wrapper py-2 order-solid border-1 border-gray-500 border-r border-t">
                  <div class="flex items-center mb-4 pb-3 pl-3 border-solid border-1 border-gray-500 border-b">
                    Other
                  </div>
                  <ul v-for="sub_tag in other_tags" :key="sub_tag.id">
                    <li>
                      <div class="flex pl-3 items-center mb-1">
                        <input :id="'tag-checkbox_' + sub_tag.id" @change="updateTags" type="checkbox"
                          :value="sub_tag.id" v-model="image.tags"
                          class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                        <label :for="'tag-checkbox_' + sub_tag.id"
                          class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">{{ sub_tag.name }}</label>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </TabPanel>

        <TabPanel as="div" style="height: calc(100vh - 202px);" id="image-cropping-tab" :class="[]">
          <ImageCropping ref="imageCroppingRef" @toggle-cropping-processed="toggleProcessingStatus"
            @component-mounted="initCroppingTab" :image_id=image_id />
        </TabPanel>

        <TabPanel as="div" id="image-copy-tab" :class="[]">
          <!-- <font-awesome-icon :icon="['fas', 'robot']" /> -->
          <ImageCopy ref="imageCopyRef" @toggle-copy-processed="toggleCopyStatus" @component-mounted="initCopyTab"
            :image_id=image_id />
        </TabPanel>
      </TabPanels>
    </TabGroup>
  </div>
  <ModalsContainer />
</template>

<script>
import axios from 'axios';
import ImageCropping from '@/components/ImageCropping.vue';
import ImageCopy from '@/components/ImageCopy.vue';
import { ModalsContainer, useModal } from 'vue-final-modal'
import ModalConfirmPlainCss from '@/components/modal/ModalConfirmPlainCss.vue'

import { ref } from 'vue'
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from '@headlessui/vue'

const tab_index = {
  "image": 0,
  "crop": 1,
  "copy": 2
}


var modal_content = ''
const { open, close } = useModal({
  component: ModalConfirmPlainCss,
  attrs: {
    title: 'Loading..',
    clickToClose: false,
    escapeToClose: false
  },
  slots: {
    default: modal_content,
  },
})

export default {
  data() {
    return {
      image_id: null,
      image: {},
      available_tags: [],
      tags: [],
      top_level_tags: [],
      other_tags: [],
      selectedTab: ref(0),
      isLoading: true,
    };
  },
  methods: {
    async fetchImageData() {
      var self = this;
      axios.get('/api/source-image/' + this.image_id + '/?queue=' + this.$route.query.queue)
        .then(response => {
          self.image = response.data;
          var url_anchor = window.location.hash;
          if (url_anchor) {
            self.selectedTab = tab_index[url_anchor.substring(1)];
          }
          this.isLoading = false;
          close();
          if (this.$refs.imageCroppingRef)
            this.$refs.imageCroppingRef.init(self.image);

          if (this.$refs.imageCopyRef)
            this.$refs.imageCopyRef.init(self.image);
        })
        .catch(error => {
          console.error("There was an error fetching the image count:", error);
          self.error = "Error fetching images";
        });

      axios.get('/api/shopify-tags/')
        .then(response => {

          self.top_level_tags = response.data.filter(tag => tag.parents.length === 0);
          self.top_level_tags = self.top_level_tags.map(tag => tag.id);
          self.available_tags = response.data;

          // Add children to parents
          for (let i = 0; i < self.available_tags.length; i++) {
            let tag = self.available_tags[i];
            if (tag.parents) {
              for (let j = 0; j < tag.parents.length; j++) {
                let parent = self.available_tags.find(parent => parent.id === tag.parents[j]);
                if (!parent.children) {
                  parent.children = [];
                }
                parent.children.push(tag);
              }
            }
          }

          // Collect tags without parents or children
          self.other_tags = response.data.filter(tag => tag.parents.length === 0 && !tag.children);
        })
        .catch(error => {
          console.error("There was an error fetching the image count:", error);
          self.error = "Error fetching images";
        });
    },
    initCroppingTab() {
      this.$refs.imageCroppingRef.init(this.image);
    },
    initCopyTab() {
      this.$refs.imageCopyRef.init(this.image);
    },
    updateCopyStatus(e) {
      e.preventDefault();
      if (!this.image.title || !this.image.description || !this.image.meta_description || !this.image.meta_title) {
        if (this.image.copy_approved) {
          this.image.copy_approved = false;
          this.$root.showToast("Can't set copy approved without setting all copy fields.", "error")
        }
      }
      this.updateImage();
    },
    updateProcessingStatus(e) {
      e.preventDefault();
      if (this.image.tags.length == 0) {
        if (this.image.image_processing_done) {
          this.image.image_processing_done = false;
          this.$root.showToast("Can't set image to processed without setting at least one tag.", "error")
        }
      }
      this.updateImage();
    },
    updateActiveStatus() {
      this.updateImage();
    },
    updateTags() {
      for (let i = 0; i < this.image.tags.length; i++) {
        // Get entry from this.tags
        let tag = this.available_tags.find(tag => tag.id === this.image.tags[i]);
        // Push tag parents
        if (tag.parents) {
          for (let j = 0; j < tag.parents.length; j++) {
            if (!this.image.tags.includes(tag.parents[j])) {
              this.image.tags.push(tag.parents[j]);
            }
          }
        }
      }
      this.updateImage();
    },
    updateImage() {
      var self = this;
      axios.put('/api/source-image/' + this.image_id + '/', this.image)
        // .then(response => {
        // })
        .catch(error => {
          console.error("There was an error fetching the image count:", error);
          self.error = "Error fetching images";
        });
    },
    changeTab(index) {
      this.selectedTab = index;
      // Unfocus 
      document.activeElement.blur();
    },
    initImage() {
      open();
      this.image_id = parseInt(this.$route.params.id);
      this.fetchImageData();
    },
    toggleProcessingStatus() {
      this.image.image_processing_done = !this.image.image_processing_done;
      this.$root.showToast("Image cropping set to " + (this.image.image_processing_done ? 'processed' : 'unprocessed'), "success")

      this.updateImage();
    },
    toggleCopyStatus() {
      this.image.copy_approved = !this.image.copy_approved;
      this.$root.showToast("Image copy set to " + (this.image.copy_approved ? 'processed' : 'unprocessed'), "success")

      this.updateImage();
    },
    keyboardHandler(e) {

      if (this.isLoading) {
        return;
      }
      const self = this;
      if (e.keyCode === 33) { // PageUp
        e.preventDefault();
        let targetId;
        if (e.shiftKey) {
          targetId = self.image.last_image_id;
        } else {
          targetId = self.image.next_image_id;
        }
        self.$router.push({ name: "Process Image", params: { id: targetId }, query: { queue: self.$route.query.queue }, hash: self.$route.hash });
        setTimeout(() => { self.initImage(); }, 100);
      } else if (e.keyCode === 34) { // PageDown
        e.preventDefault();
        let targetId;
        if (e.shiftKey) {
          targetId = self.image.first_image_id;
        } else {
          targetId = self.image.prev_image_id;
        }
        self.$router.push({ name: 'Process Image', params: { id: targetId }, query: { queue: self.$route.query.queue }, hash: self.$route.hash });
        setTimeout(() => { self.initImage(); }, 100);
        // setTimeout(() => { window.location.reload(); }, 100);
      } else if ([1, 2, 3].includes(parseInt(e.key))) {
        let tabKeys = Object.keys(tab_index);
        self.$router.push({ name: 'Process Image', params: { id: self.image_id }, query: { queue: self.$route.query.queue }, hash: '#' + tabKeys[e.key - 1] });
        self.changeTab(e.key - 1);
      }
    }
  },
  mounted() {
    var self = this;
    self.initImage();
    window.addEventListener('keydown', this.keyboardHandler);
  },
  beforeUnmount() {
    window.removeEventListener('keydown', this.keyboardHandler);
  },
  components: {
    ImageCropping,
    TabGroup,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
    ImageCopy,
    ModalsContainer
  },
};
</script>

<style scoped>
#orig-image-wrapper {
  text-align: center;
  width: 500px;
  margin: 0 auto;
}

#orig-image-wrapper img {
  width: 500px;
}

.tag-category-wrapper {
  text-align: left;
}

/* .tag-category-wrapper:not(:last-of-type) {
  border-right: 1px solid #ccc;
} */

.tablist button:active,
.tablist button:focus,
.tablist button:focus-visible {
  outline: none;
}
</style>