<template>
    <carousel @drop="wrongDropHandler" ref="croppingCarousel" class="carousel-wrapper dark:text-gray-200"
        :touchDrag="false" :mouseDrag="false" :items-to-show="1" @slide-end="handleSlideEnd"
        @slide-start="handleSlideStart">
        <template #addons>
            <div v-if="current_resolution"
                class="cropping-footer fixed bottom-0 left-0 z-50 grid w-full h-40 grid-cols-1 px-16 bg-white border-t border-gray-200 md:grid-cols-3 dark:bg-gray-600 dark:border-gray-500">
                <div class="items-center justify-center hidden text-gray-500 dark:text-gray-400 me-auto md:flex">
                    <p><span class="text-md font-bold">{{ current_resolution.resolution }}</span><br />
                        <span class="text-sm">{{ enabledVariants }} / {{ resolution_data.length }} enabled
                            variants</span>
                    </p>
                </div>
                <div class="flex items-center justify-center mx-auto">
                    <div class="preview-entry-info">
                        <h3 style="margin: 0;" class="text-slate"></h3>
                        <navigation />
                        <div class="flex align-items-center justify-evenly mx-auto">
                            <div class="grid max-w-xs grid-cols-2 gap-1 p-1 mx-2 my-2 bg-gray-100 rounded-lg dark:bg-gray-700"
                                role="group">
                                <label type="button" class="px-5 py-1.5 text-xs font-medium rounded-lg"
                                    :class="current_resolution.orientation == 'landscape' ? 'text-white bg-gray-900 dark:bg-gray-300 dark:text-gray-900' : 'text-gray-900 hover:bg-gray-200 dark:text-white dark:hover:bg-gray-700'">
                                    Landscape
                                    <input type="radio" v-model="current_resolution.orientation" value="landscape"
                                        @change="updateVariant();"
                                        :checked="current_resolution.orientation == 'landscape'"
                                        style="display: none;" />
                                </label>
                                <label type="button"
                                    :class="current_resolution.orientation == 'portrait' ? 'text-white bg-gray-900 dark:bg-gray-300 dark:text-gray-900' : 'text-gray-900 hover:bg-gray-200 dark:text-white dark:hover:bg-gray-700'"
                                    class="px-5 py-1.5 text-xs font-medium rounded-lg">
                                    Portrait
                                    <input type="radio" v-model="current_resolution.orientation" value="portrait"
                                        style="display: none;" @change="updateVariant();" />
                                </label>
                            </div>
                            <label class="inline-flex items-center cursor-pointer">
                                <input type="checkbox" v-model="current_resolution.enabled" @change="updateVariant()"
                                    class="sr-only peer">
                                <div
                                    class="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:w-5 after:h-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600">
                                </div>
                                <span class="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">Active</span>
                            </label>
                        </div>
                        <div class="relative mb-9 mt-3">
                            <label for="labels-range-input" class="sr-only">Labels range</label>
                            <input id="labels-range-input" type="range" step=".1" min="1" max="300"
                                v-model="current_resolution.scale" v-on:change="changeScale(current_resolution);"
                                v-on:input="changeScale(current_resolution, false)"
                                class="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-500">
                            <span class="text-sm text-gray-500 dark:text-gray-400 absolute start-0 -bottom-6">1%</span>
                            <span
                                class="text-sm text-gray-500 dark:text-gray-400 absolute start-1/3 -bottom-6">100%</span>
                            <span
                                class="text-sm text-gray-500 dark:text-gray-400 absolute start-2/3 -bottom-6">200%</span>
                            <span class="text-sm text-gray-500 dark:text-gray-400 absolute end-0 -bottom-6">300%</span>
                        </div>
                        <pagination />

                    </div>
                </div>
                <div class="items-center justify-center hidden ms-auto md:flex">
                    <button data-tooltip-target="tooltip-pixel-preview" @click="open_modal()" type="button"
                        class="p-2.5 group rounded-full hover:bg-gray-100 me-1 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-600 dark:hover:bg-gray-600">
                        <FontAwesomeIcon icon="eye" />
                        <span class="sr-only">Pixel Preview</span>
                    </button>
                    <button data-tooltip-target="tooltip-pixel-preview" @click="reset_image()" type="button"
                        class="p-2.5 group rounded-full hover:bg-gray-100 me-1 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-600 dark:hover:bg-gray-600">
                        <FontAwesomeIcon :icon="['fas', 'arrow-rotate-left']" />
                        <span class="sr-only">Reset</span>
                    </button>

                </div>
            </div>
        </template>
        <slide class="preview-wrapper" v-for="resolution in  resolution_data " :key="resolution">
            <div class="preview-image-wrapper">
                <br />
                <div class="image-border-wrapper">
                    <div class="image-preview-border border-2 border-solid "
                        :class="currentResolutionFillsBorder == true ? 'border-gray-500 dark:border-gray-300' : 'border-red-500'"
                        :style="{ width: resolution.orientation == 'portrait' ? resolution.width_frame_px + 'px' : resolution.height_frame_px + 'px', height: resolution.orientation == 'portrait' ? resolution.height_frame_px + 'px' : resolution.width_frame_px + 'px' }">
                        <img class="preview-image" :src="config.mediaRoot + image.image" :style="{
            width: resolution.scaled_width + 'px', height: resolution.scaled_height + 'px', top: resolution.top + 'px', left: resolution.left + 'px'
        }" :alt="resolution" @dragstart="startDragging">
                    </div>
                </div>
            </div>
        </slide>

    </carousel>

    <ModalsContainer />
</template>

<script>
import axios from 'axios';
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
import { ModalsContainer, useModal } from 'vue-final-modal'
import ModalConfirmPlainCss from '@/components/modal/ModalConfirmPlainCss.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

var modal_content = ''
const { open, close } = useModal({
    component: ModalConfirmPlainCss,
    attrs: {
        title: 'Loading Preview..',
        clickToClose: false,
        onConfirm() {
            close()
        },
    },
    slots: {
        default: modal_content,
    },
})

export default {
    data() {
        return {
            resolutions: [],
            resolution_data: [],
            draggingResolution: null,
            initial_dragging_top: 0,
            initial_dragging_left: 0,
            initial_image_top: 0,
            initial_image_left: 0,
            dragImg: new Image(),
            source_image_width: 0,
            source_image_height: 0,
            current_resolution: null,
            is_dragging: false,
            image: {},
            updateVariantTimeout: null,
        };
    },
    props: {
        image_id: {
            type: Number,
            required: true
        }
    },
    computed: {
        enabledVariants: {
            get() {
                return this.resolution_data.filter((resolution) => resolution.enabled).length;
            },
        },
        currentResolutionFillsBorder() {
            var x_valid = false;
            var y_valid = false;

            var target_width = this.current_resolution.orientation == "portrait" ? this.current_resolution.width_frame_px : this.current_resolution.height_frame_px;
            var target_height = this.current_resolution.orientation == "portrait" ? this.current_resolution.height_frame_px : this.current_resolution.width_frame_px;

            if (this.current_resolution.left <= 0) {
                x_valid = this.current_resolution.scaled_width + this.current_resolution.left >= target_width;
            } else {
                x_valid = false;
            }

            if (this.current_resolution.top <= 0) {
                y_valid = this.current_resolution.scaled_height + this.current_resolution.top >= target_height;
            } else {
                y_valid = false;
            }
            return x_valid && y_valid
        },
    },
    methods: {
        open_modal() {
            open().then(() => {
                this.updateVariant(() => {
                    axios.get('/api/source-image/' + this.image_id + '/variant/' + this.current_resolution.resolution + '/pixel-preview/').then(response => {
                        document.querySelector('.vfm__content').innerHTML = '<img src="' + response.data + '" style="max-width: 80vw; max-height: 80vh;" alt="Original Image">';
                        document.querySelector('.vfm__content').style.background = '#fff';
                    });
                });
            });
        },
        reset_image() {
            this.current_resolution.top = 0;
            this.current_resolution.left = 0;
            this.current_resolution.scale = 100;
            this.current_resolution.orientation = "portrait";
            this.changeScale(this.current_resolution, false);
            this.updateVariant();
        },
        orderVariantsByEnabled() {
            var enabled_variants = this.resolution_data.filter((resolution) => resolution.enabled);
            var disabled_variants = this.resolution_data.filter((resolution) => !resolution.enabled);

            enabled_variants = enabled_variants.sort((a, b) => {
                return a.resolution.localeCompare(b.resolution);
            });

            disabled_variants = disabled_variants.sort((a, b) => {
                return a.resolution.localeCompare(b.resolution);
            });
            this.resolution_data = enabled_variants.concat(disabled_variants);

            this.current_resolution = this.resolution_data[0];
        },
        async fetchVariantData(callback) {
            var self = this;
            axios.get('/api/source-image/' + this.image_id + '/variants/')
                .then(response => {
                    var formatted_data = {};
                    for (var i = 0; i < response.data.length; i++) {
                        formatted_data[response.data[i].resolution] = response.data[i];
                    }
                    callback(formatted_data);
                })
                .catch(error => {
                    console.error("There was an error fetching the image count:", error);
                    self.error = "Error fetching images";
                });
        },
        async fetchDiamondPaintingSizes(callback) {
            var self = this;
            axios.get('/api/diamond-painting-sizes/')
                .then(response => {
                    var sizes = [];
                    for (var i = 0; i < response.data.length; i++) {
                        sizes.push(response.data[i].name);
                    }
                    callback(sizes);
                })
                .catch(error => {
                    console.error("There was an error fetching the image count:", error);
                    self.error = "Error fetching images";
                });
        },
        async fetchImageData() {
            var self = this;
            axios.get('/api/source-image/' + this.image_id + '/')
                .then(response => {
                    self.image = response.data;
                })
                .catch(error => {
                    console.error("There was an error fetching the image count:", error);
                    self.error = "Error fetching images";
                });
        },
        startDragging(e) {
            e.preventDefault();
            var self = this;
            this.is_dragging = true;

            this.initial_dragging_top = event.clientY;
            this.initial_dragging_left = event.clientX;
            this.initial_image_top = this.current_resolution.top;
            this.initial_image_left = this.current_resolution.left;

            window.addEventListener('mousemove', self.dragging);
            window.addEventListener('mouseup', self.endDragging);
            // event.dataTransfer.setDragImage(this.dragImg, 0, 0);
        },
        handleDrop(e) {
            e.preventDefault();
            var resolution = this.current_resolution;
            e.preventDefault();
            this.endDragging(resolution);
        },
        dragging(e) {
            e.preventDefault();
            const offsetX = this.initial_image_left + (event.clientX - this.initial_dragging_left);
            const offsetY = this.initial_image_top + (event.clientY - this.initial_dragging_top);

            this.current_resolution.top = offsetY;
            this.current_resolution.left = offsetX;
        },
        endDragging(e) {
            e.preventDefault();
            this.is_dragging = false;
            window.removeEventListener('mousemove', this.dragging);
            this.updateVariant();
            // event.preventDefault();
            // var resolution = this.current_resolution;
            // console.log("End drag");
            // if (this.draggingResolution === resolution) {
            //     const offsetX = this.initial_image_left + (event.clientX - this.initial_dragging_left);
            //     const offsetY = this.initial_image_top + (event.clientY - this.initial_dragging_top);

            //     resolution.top = offsetY;
            //     resolution.left = offsetX;
            //     this.draggingResolution = null;

            //     this.updateVariant();
            // }
        },
        changeScale(resolution, update = true) {
            resolution.scaled_width = (resolution.source_image_width * (resolution.scale / 100)) / resolution.preview_scale;
            resolution.scaled_height = (resolution.source_image_height * (resolution.scale / 100)) / resolution.preview_scale;
            if (update)
                this.updateVariant();
        },

        updateOrientation() {
        },
        handleSlideStart() {
            // this.updateVariant();
        },
        handleSlideEnd(data) {
            this.current_resolution = this.resolution_data[data.currentSlideIndex];
        },
        wrongDropHandler() {
            // event.preventDefault();
        },
        prepResolutionData() {
            this.resolution_data = [];
            this.fetchDiamondPaintingSizes((sizes) => {
                this.resolutions = sizes;
                this.fetchVariantData((variant_data) => {
                    this.dragImg = new Image(0, 0);
                    var self = this;
                    this.dragImg.src =
                        'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
                    for (var i = 0; i < this.resolutions.length; i++) {
                        var split = this.resolutions[i].split("x");
                        var width = parseInt(split[0]);
                        var height = parseInt(split[1]);

                        var preview_scale = 5;
                        if (width > height) {
                            preview_scale = width / 100;
                        } else {
                            preview_scale = height / 100;
                        }
                        // Calculate resolution in cm with 72 dpi
                        var mm_width = ((self.source_image_width / 2.54) * 72) / preview_scale;
                        var mm_height = ((self.source_image_height / 2.54) * 72) / preview_scale;

                        var width_frame_px = Math.round((width * 72) / 25.4) / preview_scale;
                        var height_frame_px = Math.round((height * 72) / 25.4) / preview_scale;

                        var max_width = (width > height) ? width : height;
                        var max_height = (width > height) ? width : height;

                        var current_top = 0;
                        var current_left = 0;
                        var current_scale = 100;
                        var current_orientation = "portrait";
                        var enabled = false;

                        if (variant_data[this.resolutions[i]]) {
                            current_top = variant_data[this.resolutions[i]].y_offset / preview_scale;
                            current_left = variant_data[this.resolutions[i]].x_offset / preview_scale;
                            current_scale = variant_data[this.resolutions[i]].scale * 100;
                            current_orientation = variant_data[this.resolutions[i]].orientation;
                            enabled = variant_data[this.resolutions[i]].enabled;
                        }

                        var scaled_height = (self.source_image_height * (current_scale / 100)) / preview_scale;
                        var scaled_width = (self.source_image_width * (current_scale / 100)) / preview_scale;


                        this.resolution_data.push({
                            resolution: this.resolutions[i],
                            width: width,
                            enabled: enabled,
                            height: height,
                            source_image_height: self.source_image_height,
                            source_image_width: self.source_image_width,
                            max_width: max_width,
                            max_height: max_height,
                            mm_height: mm_height,
                            mm_width: mm_width,
                            scaled_width: scaled_width,
                            scaled_height: scaled_height,
                            width_frame_px: width_frame_px,
                            height_frame_px: height_frame_px,
                            scale: current_scale,
                            top: current_top, // Initial top position
                            left: current_left, // Initial left position
                            orientation: current_orientation,
                            preview_scale: preview_scale,
                        });
                    }
                    self.isLoading = false;
                    this.current_resolution = this.resolution_data[0];
                    this.orderVariantsByEnabled();
                });
            });
        },
        updateVariant(callback = null) {
            if (this.updateVariantTimeout) {
                clearTimeout(this.updateVariantTimeout);
            }

            this.updateVariantTimeout = setTimeout(() => {
                var update_data = {
                    "enabled": this.current_resolution.enabled,
                    "diamond_type": "square",
                    "x_offset": this.current_resolution.left * this.current_resolution.preview_scale,
                    "y_offset": this.current_resolution.top * this.current_resolution.preview_scale,
                    "scale": this.current_resolution.scale / 100,
                    "orientation": this.current_resolution.orientation,
                }

                axios.put('/api/source-image/' + this.image_id + '/variant/' + this.current_resolution.resolution + "/", update_data)
                    .then(response => {
                        if (callback) {
                            callback(response);
                        }
                    })
                    .catch(error => {
                        console.error("There was an error fetching the image count:", error);
                        self.error = "Error fetching images";
                        self.isLoading = false;
                    });
            }, 100);
        },
        init(image) {
            this.image = image;
            this.source_image_height = image.height;
            this.source_image_width = image.width;
            this.prepResolutionData();
        },
        keyboardCallbacks(e) {
            var target_key = e.key.toLowerCase();
            if (target_key === 'w') {
                this.current_resolution.top -= e.shiftKey ? 10 : 1;
                this.updateVariant();
            } else if (target_key === 's') {
                this.current_resolution.top += e.shiftKey ? 10 : 1;
                this.updateVariant();
            } else if (target_key === 'a') {
                this.current_resolution.left -= e.shiftKey ? 10 : 1;
                this.updateVariant();
            } else if (target_key === 'd') {
                this.current_resolution.left += e.shiftKey ? 10 : 1;
                this.updateVariant();
            } else if (target_key === 'r') {
                if (e.shiftKey || e.ctrlKey || e.metaKey) {
                    return;
                }
                this.reset_image();
                this.updateVariant();
            } else if (target_key === 'q') {
                this.current_resolution.scale -= e.shiftKey ? 10 : 1;
                this.changeScale(this.current_resolution, true);
            } else if (target_key === 'e') {
                this.current_resolution.scale += e.shiftKey ? 10 : 1;
                this.changeScale(this.current_resolution, true);
            } else if (e.key === 'o') {
                this.current_resolution.orientation = this.current_resolution.orientation == 'portrait' ? 'landscape' : 'portrait';
                this.updateVariant();
            } else if (e.key === ' ') {
                this.current_resolution.enabled = !this.current_resolution.enabled;
                this.updateVariant();
            } else if (e.key === 'ArrowLeft') {
                this.$refs.croppingCarousel.prev();
            } else if (e.key === 'ArrowRight') {
                this.$refs.croppingCarousel.next();
            } else if (e.key == "Enter") {
                if (e.ctrlKey || e.metaKey)
                    this.$emit('toggle-cropping-processed');
            }
        },
    },
    mounted() {
        console.log("Crop mount");
        // Add left and right arrow key listeners
        window.addEventListener('keydown', this.keyboardCallbacks);
        this.$emit('component-mounted');
    },

    beforeUnmount() {
        console.log("Crop unmount");
        // Clean up global event listeners
        // Remove left and right arrow key listeners
        window.removeEventListener('keydown', this.keyboardCallbacks);
    },

    components: {
        Carousel,
        Slide,
        Pagination,
        Navigation,
        ModalsContainer,
        FontAwesomeIcon
    },
};

</script>

<style scoped>
.preview-wrapper {
    flex-flow: wrap;
    position: relative;
}

.preview-image-wrapper {
    position: relative;
    margin: 0 auto;
    width: 100%;
}

.image-preview-border {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 5;
    overflow: hidden;
    transform-origin: center;
}

.preview-entry-info {
    width: 500px;
    margin: 0 auto;
}

.preview-image {
    position: absolute;
    z-index: 4;
    cursor: move;
    max-width: none;
    /* Add cursor style for dragging */
}

.carousel__slide {
    align-items: start;
    /* height: calc(100vh - 205px);
    overflow-y: hidden; */
}

.image-border-wrapper {
    position: relative;
    /* display: flex; */
    max-height: 100vh;
    min-height: 50vh;
    align-items: center;
    height: calc(100vh - 550px);
}

.carousel-wrapper,
.carousel__viewport,
.carousel__track {}
</style>

<style>
.vfm__content,
.confirm-modal-content {
    background: none;
}

.v-spinner {
    margin: 0 auto;
}
</style>