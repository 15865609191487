let config;

if (process.env.NODE_ENV === "production") {
    config = {
        $apiUrl: "https://tools.diamant-bilder.com/backend",
        mediaRoot: ""
    };
} else {
    config = {
        $apiUrl: "http://localhost:8010",
        mediaRoot: "http://localhost:8010"
    };
}

export { config }