<template>
  <nav v-if="isLoggedIn == true && !isShopifyView" class="bg-white border-gray-200 dark:bg-gray-900 p-0">
    <div class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto py-4 px-4">
      <a href="/" class="flex items-center space-x-3 rtl:space-x-reverse">
        <img :src="image" class="h-12" alt="Logo" />
        <span class="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">DiamantBilder</span>
      </a>
      <button data-collapse-toggle="navbar-default" type="button"
        class="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
        aria-controls="navbar-default" aria-expanded="false">
        <span class="sr-only">Open main menu</span>
        <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
            d="M1 1h15M1 7h15M1 13h15" />
        </svg>
      </button>
      <div class="hidden w-full md:block md:w-auto" id="navbar-default">
        <ul
          class="font-medium flex flex-col p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
          <li>
            <router-link
              class="block py-2 px-3 text-white bg-blue-700 rounded md:bg-transparent md:text-gray-800 dark:md:text-gray-400 md:p-0 dark:text-white hover:dark:text-gray-200"
              to="/dashboard">Home</router-link>
          </li>
          <li>
            <router-link
              class="block py-2 px-3 text-white bg-blue-700 rounded md:bg-transparent md:text-gray-800 dark:md:text-gray-400 md:p-0 dark:text-white hover:dark:text-gray-200"
              to="/generation-overview">Generator</router-link>
          </li>
          <!-- <li>
            <router-link
              class="block py-2 px-3 text-white bg-blue-700 rounded md:bg-transparent md:text-gray-800 dark:md:text-gray-400 md:p-0 dark:text-white hover:dark:text-gray-200"
              to="/collections">Collections</router-link>
          </li> -->
          <li>
            <a class="block py-2 px-3 text-white bg-blue-700 rounded md:bg-transparent md:text-gray-800 dark:md:text-gray-400 md:p-0 dark:text-white hover:dark:text-gray-200"
              href="/admin">Admin</a>
          </li>
          <li>
            <router-link
              class="block py-2 px-3 text-white bg-blue-700 rounded md:bg-transparent md:text-gray-800 dark:md:text-gray-400 md:p-0 dark:text-white hover:dark:text-gray-200"
              to="/logout">
              <font-awesome-icon :icon="['fas', 'arrow-right-from-bracket']" />
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <div class="dark:bg-gray-800" :class="isLoggedIn != true ? 'flex align-center justify-center' : ''"
    style="min-height: calc(100% - 80px);">
    <router-view />
  </div>
  <ErrorToast ref="errorToast" />
  <SuccessToast ref="sucessToast" />

</template>

<script>
import image from "./assets/logo.webp"
import auth from "@/auth"
import ErrorToast from '@/components/toast/ErrorToast.vue';
import SuccessToast from '@/components/toast/SuccessToast.vue';

export default {
  data: function () {
    return {
      image: image,
      isLoggedIn: false,
      isShopifyView: false
    }
  },
  async mounted() {
    this.isLoggedIn = await auth.isAuthenticated()
  },
  methods: {
    showToast(message, type = "error") {
      if (type === "error") {
        this.$refs.errorToast.show(message);
      } else if (type === "success") {
        this.$refs.sucessToast.show(message);
      }
    }
  },
  components: {
    ErrorToast,
    SuccessToast
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: calc(100vh - 80px);
}

#app * {
  outline: none;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}


nav a.router-link-exact-active {
  color: white;
  border-bottom: 2px solid white;
}
</style>
