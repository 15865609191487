<template>
  <div class="dashboard flex flex-wrap">
    <a v-for="tag in available_tags" :key="tag" :href="'/collection/' + tag.id"
      class="p-5 dark:text-gray-400 rounded-lg border border-1 border-gray-400 mx-5 my-2 dark:hover:bg-gray-700">
      <h3>{{ tag.name }}</h3>
    </a>
  </div>
</template>


<script>
import axios from 'axios';
// import { mapState } from 'vuex';

export default {
  data() {
    return {
      available_tags: [],
    };
  },
  methods: {
    async fetchData() {
      const self = this;
      axios.get('/api/shopify-tags/')
        .then(response => {
          self.available_tags = response.data;
          console.log("Available tags:", self.available_tags);
        })
        .catch(error => {
          console.error("There was an error fetching the image count:", error);
          self.error = "Error fetching images";
        });
    }
  },
  components: {
  },
  mounted() {
    console.log("Overview")
    this.fetchData();
  }
};
</script>

<style scoped>
.dashboard {
  padding: 20px;
  border-radius: 4px;
  text-align: center;
}

.error {
  color: red;
}
</style>