<template>
    <div class="flex mt-20 pt-15">
        <form class="w-full max-w-lg m-auto">
            <div class="flex items-center border-b border-blue-700 py-2">
                <input
                    class="appearance-none bg-transparent dark:text-white border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
                    type="text" placeholder="Title" v-on:change="updateImage" v-model="image.title" aria-label="Title">
                <button v-if="!titleLoading"
                    class="flex-shrink-0 bg-white dark:bg-blue-600 border-transparent dark:hover:bg-blue-700 text-sm border-4 text-white hover:border-4 hover:text-white py-1 px-2 rounded"
                    type="button" v-on:click="generateTitle">
                    <FontAwesomeIcon icon="robot" />
                </button>
                <clip-loader v-if="titleLoading" :loading="titleLoading" color="#4a5568" size="20px"
                    style="display: flex; align-items: center; width: 33px;" class="ml-2"></clip-loader>
            </div>
            <label for="title"
                class="block px-3 pt-2 text-sm text-left font-medium text-gray-700 dark:text-gray-500">Title</label>
            <div
                class="w-full mb-4 mt-5 border border-gray-200 rounded-lg bg-gray-50 dark:bg-gray-700 dark:border-gray-600">
                <div class="px-4 py-2 bg-white rounded-t-lg dark:bg-gray-800">
                    <label class="text-left dark:text-gray-500 dark:border-b dark:border-gray-700"
                        for="comment">Description</label>
                    <textarea id="comment" rows="4" v-on:change="updateImage" v-model="image.description"
                        class="w-full h-40 mt-2 focus:outline-none px-0 text-sm text-gray-900 bg-white border-0 dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400"
                        placeholder="Write a description..." required></textarea>
                </div>
                <div class="flex items-center justify-between px-3 py-2 border-t dark:border-gray-600">
                    <div class="flex ps-0 space-x-1 rtl:space-x-reverse sm:ps-2">
                        <button type="button" v-on:click="generateDescription" v-if="!descriptionLoading"
                            class="inline-flex justify-center items-center p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600">
                            <FontAwesomeIcon icon="robot" />
                            <span class="sr-only">Generate description</span>
                        </button>
                        <clip-loader v-if="descriptionLoading" :loading="descriptionLoading" color="#4a5568" size="20px"
                            style="display: flex; align-items: center; width: 33px;" class="ml-2"></clip-loader>
                    </div>
                </div>
            </div>
        </form>
        <form class="w-full max-w-lg m-auto">

            <div class="flex items-center border-b border-blue-700 py-2">
                <input
                    class="appearance-none bg-transparent dark:text-white border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
                    type="text" placeholder="Meta Title" v-on:change="updateImage" v-model="image.meta_title"
                    aria-label="Meta Title">
                <clip-loader v-if="metaTitleLoading" :loading="metaTitleLoading" color="#4a5568" size="20px"
                    style="display: flex; align-items: center; width: 33px;" class="ml-2"></clip-loader>
                <button v-if="!metaTitleLoading" v-on:click="generateMetaTitle"
                    class="flex-shrink-0 bg-white dark:bg-blue-600 border-transparent dark:hover:bg-blue-700 text-sm border-4 text-white hover:border-4 hover:text-white py-1 px-2 rounded"
                    type="button">
                    <FontAwesomeIcon icon="robot" />
                </button>
            </div>
            <label for="title"
                class="block px-3 pt-2 text-sm text-left font-medium text-gray-700 dark:text-gray-500">Meta
                Title</label>
            <div
                class="w-full mb-4 mt-5 border border-gray-200 rounded-lg bg-gray-50 dark:bg-gray-700 dark:border-gray-600">
                <div class="px-4 py-2 bg-white rounded-t-lg dark:bg-gray-800">
                    <label class="text-left dark:text-gray-500 dark:border-b dark:border-gray-700" for="comment">
                        Meta Description</label>
                    <textarea id="comment" rows="4" v-on:change="updateImage" v-model="image.meta_description"
                        class="w-full h-40 mt-2 focus:outline-none px-0 text-sm text-gray-900 bg-white border-0 dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400"
                        placeholder="Write a meta description..." required></textarea>
                </div>
                <div class="flex items-center justify-between px-3 py-2 border-t dark:border-gray-600">
                    <div class="flex ps-0 space-x-1 rtl:space-x-reverse sm:ps-2">
                        <button v-if="!metaDescriptionLoading" type="button" v-on:click="generateMetaDescription"
                            class="inline-flex justify-center items-center p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600">
                            <FontAwesomeIcon icon="robot" />
                            <span class="sr-only">Generate meta description</span>
                        </button>
                        <clip-loader v-if="metaDescriptionLoading" :loading="metaDescriptionLoading" color="#4a5568"
                            size="20px" style="display: flex; align-items: center; width: 33px;"
                            class="ml-2"></clip-loader>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'

export default {
    data() {
        return {
            image: {},
            titleLoading: false,
            metaTitleLoading: false,
            descriptionLoading: false,
            metaDescriptionLoading: false
        };
    },
    props: {
        image_id: {
            type: Number,
            required: true
        }
    },
    methods: {
        init(image) {
            this.image = image;
        },
        mounted() {
        },
        updateImage() {
            var self = this;
            axios.put('/api/source-image/' + this.image_id + '/', this.image)
                // .then(response => {

                // })
                .catch(error => {
                    self.$root.showToast('Server error');
                    console.error("There was an error fetching the image count:", error);
                    self.error = "Error fetching images";
                });
        },
        generateTitle() {
            this.titleLoading = true;
            var self = this;
            axios.get('/api/source-image/' + this.image_id + '/generate-title/')
                .then(response => {
                    this.image.title = response.data.title;
                    this.titleLoading = false;
                    this.updateImage();
                })
                .catch(error => {
                    self.$root.showToast('Server error');
                    self.titleLoading = false;
                    console.error("There was an error fetching the image count:", error);
                    self.error = "Error fetching images";
                });
        },
        generateMetaTitle() {
            if (!this.image.title) {
                this.$root.showToast('Please generate a title first');
                return;
            }
            this.metaTitleLoading = true;
            var self = this;
            axios.get('/api/source-image/' + this.image_id + '/generate-meta-title/')
                .then(response => {
                    this.image.meta_title = response.data.meta_title;
                    this.metaTitleLoading = false;
                    this.updateImage();
                })
                .catch(error => {
                    self.$root.showToast('Server error');
                    self.metaTitleLoading = false;
                    console.error("There was an error fetching the image count:", error);
                    self.error = "Error fetching images";
                });
        },
        generateDescription() {
            this.descriptionLoading = true;
            var self = this;
            axios.get('/api/source-image/' + this.image_id + '/generate-description/')
                .then(response => {
                    this.image.description = response.data.description;
                    this.descriptionLoading = false;
                    this.updateImage();
                })
                .catch(error => {
                    self.$root.showToast('Server error');
                    self.descriptionLoading = false;
                    console.error("There was an error fetching the image count:", error);
                    self.error = "Error fetching images";
                });
        },
        generateMetaDescription() {
            this.metaDescriptionLoading = true;
            var self = this;
            axios.get('/api/source-image/' + this.image_id + '/generate-meta-description/')
                .then(response => {
                    this.image.meta_description = response.data.meta_description;
                    this.metaDescriptionLoading = false;
                    this.updateImage();
                })
                .catch(error => {
                    self.$root.showToast('Server error');
                    self.metaDescriptionLoading = false;
                    console.error("There was an error fetching the image count:", error);
                    self.error = "Error fetching images";
                });
        },
        keyboardCallbacks(e) {
            if (e.key == "Enter") {
                e.preventDefault();
                if (e.ctrlKey || e.metaKey)
                    this.$emit('toggle-copy-processed');
            }
        },
    },
    components: {
        FontAwesomeIcon,
        ClipLoader,
    },
    mounted() {
        window.addEventListener('keydown', this.keyboardCallbacks);
        this.$emit('component-mounted');
    },
    beforeUnmount() {
        window.removeEventListener('keydown', this.keyboardCallbacks);
    }
};
</script>

<style scoped>
.login-container {
    max-width: 300px;
    margin: auto;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.form-group {
    margin-bottom: 20px;
}

label {
    display: block;
    margin-bottom: 5px;
}

input[type="text"],
input[type="password"] {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
}
</style>