import './plugins/axios'
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import PrimeVue from 'primevue/config';
import { createVfm } from 'vue-final-modal'
import 'material-icons/iconfont/material-icons.css';
import './output.css'
import 'vue-final-modal/style.css'
/* import font awesome icon component */
import VueCookies from 'vue-cookies'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { config } from './config';

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'

library.add(fas)
var app = createApp(App)
app.config.globalProperties.config = config
app.component('font-awesome-icon', FontAwesomeIcon)
app.use(router).use(store);
app.use(createVfm());
app.use(PrimeVue);
app.use(VueCookies, { "sameSite": "none", "secure": true })
app.mount('#app');

// createApp(App).use(router).use(router).mount('#app')