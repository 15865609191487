import axios from 'axios';

export default {
    async isAuthenticated() {
        try {
            const response = await axios.get('/api/auth-status/', { withCredentials: true });
            // If response 200
            if (response.status === 200) {
                return response.data.isLoggedIn;
            } else {
                return false;
            }
        } catch (error) {
            return false;
        }
    }
}