import { createRouter, createWebHistory } from 'vue-router';
import Login from '@/components/LoginView.vue';
import Logout from '@/views/LogoutView.vue';
// import HomeView from '@/views/HomeView.vue';
import QueueView from '@/views/QueueView.vue';
import ImageProcessingView from '@/views/ImageProcessingView.vue';
import GenerationOverview from '@/views/GenerationOverview.vue';
import CollectionOverview from '@/views/CollectionOverview.vue';
import CollectionView from '@/views/CollectionView.vue';
import ShopifyUpload from '@/views/ShopifyUpload.vue';
import auth from '@/auth';
// import axios from 'axios';

const routes = [{
        path: '/',
        name: 'Home',
        redirect: '/dashboard?type=process_queue',
    },
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/logout',
        name: 'Logout',
        component: Logout
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: QueueView,
        meta: { requiresAuth: true }
    },
    {
        path: '/generation-overview',
        name: 'Generation Overview',
        component: GenerationOverview,
        meta: { requiresAuth: true }
    },
    {
        path: '/collections',
        name: 'Collection Overview',
        component: CollectionOverview,
        meta: { requiresAuth: true }
    },
    {
        path: '/collection/:id',
        name: 'Collection Details',
        component: CollectionView,
        meta: { requiresAuth: true }
    },
    {
        path: '/queue',
        name: 'Queue',
        component: QueueView,
        meta: { requiresAuth: true }
    },
    {
        path: '/process/image/:id',
        name: 'Process Image',
        component: ImageProcessingView,
        meta: { requiresAuth: true }
    },
    {
        path: '/shopify-upload/',
        name: 'Shopify Upload New',
        component: ShopifyUpload,
        meta: { requiresAuth: false }
    },
    {
        path: '/shopify-upload/:id',
        name: 'Shopify Upload',
        component: ShopifyUpload,
        meta: { requiresAuth: false }
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

router.beforeEach(async function(to, from, next) {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        var isLoggedIn = await auth.isAuthenticated();
        if (isLoggedIn == false) {
            next('/login');
            return;
        } else {
            next();
            return;
        }
        // axios.get('/api/auth-status/', { withCredentials: true })
        //     .then(response => {
        //         if (response.data.isLoggedIn) {
        //             next();
        //         } else {
        //             next('/login');
        //         }
        //     })
        //     .catch(() => {
        //         next('/login');
        //     });
    } else {
        next();
    }
});

export default router;