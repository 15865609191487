<template>
    <form class="m-auto dark:bg-gray-900 rounded-lg p-10" style="width: 300px;" @submit.prevent="login">
        <div class="mb-5">
            <label for="username" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Username</label>
            <input type="text" id="username"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="" v-model="credentials.username" required />
        </div>
        <div class="mb-5">
            <label for="password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Password</label>
            <input type="password" id="password" v-model="credentials.password"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required />
        </div>
        <p v-if="error" class="text-red-500 mb-5">{{ error }}</p>
        <button type="submit"
            class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Submit</button>
    </form>
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            credentials: {
                username: '',
                password: ''
            },
            error: null
        };
    },
    methods: {
        async login() {
            try {
                // Make the login request
                const response = await axios.post('/api/login/', this.credentials, {
                    withCredentials: true  // Ensure cookies are sent with the request
                });

                // Check the HTTP status code
                if (response.status === 200) {
                    // Navigate to the dashboard on successful login
                    this.$root.isLoggedIn = true;
                    this.$router.push('/dashboard');
                } else {
                    // Throw an error if the response code is not what you expect
                    throw new Error('Login was not successful');
                }
            } catch (e) {
                // Handle errors in login, e.g., network issues, or login being unsuccessful
                if (e.response && e.response.status === 401) {
                    this.error = 'Invalid credentials';
                } else {
                    this.error = e.message || 'An error occurred. Please try again later.';
                }
                console.error('Login error:', this.error);
            }
        }
    }
};
</script>

<style scoped>
.login-container {
    max-width: 300px;
    margin: auto;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.form-group {
    margin-bottom: 20px;
}

label {
    display: block;
    margin-bottom: 5px;
}

input[type="text"],
input[type="password"] {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
}
</style>