<template>
  <div class="dashboard">

    <ImageCountHeader />
    <div class="queue">
      <p class="dark:text-gray-400 mb-3">Showing {{ page.image_index_start }} - {{ page.image_index_end }} images ({{
        page.total_images }} available)</p>
      <div v-if="isLoading" class="loader">Loading...</div>
      <div v-else class="image_list">
        <div v-for="image in images" :key="image.id" class="image">
          <div>
            <a :href="'/process/image/' + image.id + '?queue=' + queue"><img style="max-width: 250px;"
                :src="config.mediaRoot + image.thumbnail" alt="Image" /></a>
          </div>
        </div>
      </div>

      <nav class="flex justify-center align-center">
        <ul class="flex items-center -space-x-px h-8 text-sm">
          <li>
            <a :href="'/queue?type=' + queue + '&page=' + page.prev_page"
              :class="page.page == 1 ? 'cursor-not-allowed disabled dark:bg-gray-900 hover:dark:bg-gray-900 hover:bg-white hover:dark:text-gray-400' : ''"
              class="flex items-center justify-center px-3 h-8 ms-0 leading-tight text-gray-500 bg-white border border-e-0 border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">

              <span class="sr-only">Previous</span>
              <font-awesome-icon icon="angle-left" />
            </a>
          </li>
          <li>
            <a :href="'/queue?type=' + queue + '&page=1'"
              :class="page.page == 1 ? 'cursor-not-allowed disabled dark:bg-gray-900 hover:dark:bg-gray-900 hover:dark:bg-gray-800 hover:bg-white hover:dark:text-gray-400' : ''"
              class="flex items-center justify-center px-3 h-8 ms-0 leading-tight text-gray-500 bg-white border border-e-0 border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
              <span class="sr-only">Previous</span>
              <font-awesome-icon icon="angles-left" />
            </a>
          </li>

          <li v-for="nav_page in navigation_pages" :key="nav_page">
            <a :href="'/queue?type=' + queue + '&page=' + nav_page"
              :class="nav_page == page_index ? 'z-10 flex items-center justify-center px-3 h-8 leading-tight text-blue-600 border border-blue-300 bg-blue-50 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white' : 'flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'"
              class="">{{ nav_page }}</a>
          </li>
          <li>
            <a :href="'/queue?type=' + queue + '&page=' + page.num_pages"
              :class="page.page == page.num_pages ? 'cursor-not-allowed disabled dark:bg-gray-900 hover:dark:bg-gray-900 hover:dark:bg-gray-800 hover:bg-white hover:dark:text-gray-400' : ''"
              class="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
              <span class="sr-only">Last</span>
              <font-awesome-icon icon="angles-right" />
            </a>
          </li>
          <li>
            <a :href="'/queue?type=' + queue + '&page=' + page.next_page"
              :class="page.page == page.num_pages ? 'cursor-not-allowed disabled dark:bg-gray-900 hover:dark:bg-gray-900 hover:dark:bg-gray-800 hover:bg-white hover:dark:text-gray-400' : ''"
              class="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
              <span class="sr-only">Next</span>
              <font-awesome-icon icon="angle-right" />
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import ImageCountHeader from '@/components/ImageCountHeader.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
  data() {
    return {
      queue: this.$route.query.type ? this.$route.query.type : "process_queue",
      page_index: this.$route.query.page ? parseInt(this.$route.query.page) : 1,
      image_count: 0,
      images: [],
      navigation_pages: [],
      page: {
        page: 1,
        images_per_page: 25,
        total_images: 0,
        image_index_start: 0,
        image_index_end: 0,
      },
    };
  },
  methods: {
    async fetchData() {
      var self = this;
      axios.get('/api/images-by-stage/?stage=' + this.queue + '&page=' + this.page_index)
        .then(response => {
          self.images = response.data.data;
          self.image_count = response.data.data.length;
          self.page = response.data.page;
          self.page.image_index_start = self.page.images_per_page * (self.page.page - 1) + 1;
          self.page.image_index_end = self.page_index < this.page.num_pages ? self.image_count <= self.page.images_per_page * self.page.page ? self.page.images_per_page * self.page.page : self.image_count : self.total_images;
          self.isLoading = false;

          var start_nav_index = self.page.page - 2 > 0 ? self.page.page - 2 : 1;
          if (self.page.num_pages - start_nav_index < 4) {
            start_nav_index = self.page.num_pages - 4 > 0 ? self.page.num_pages - 4 : 1;
          }
          console.log("Start nav index:", start_nav_index);
          console.log("Total pages:", self.page.num_pages);
          for (var i = start_nav_index; i <= self.page.num_pages; i++) {
            if (self.navigation_pages.length >= 5) {
              break;
            }
            self.navigation_pages.push(i);
          }
          console.log("Navigation pages:", self.navigation_pages);
        })
        .catch(error => {
          console.error("There was an error fetching the image count:", error);
          self.error = "Error fetching images";
          self.isLoading = false;
        });
    }
  },
  mounted() {
    if (this.queue == undefined) {
      this.$route.query.type = "process_queue";
      this.queue = "process_queue";
    }
    this.fetchData();
  },
  components: {
    ImageCountHeader,
    FontAwesomeIcon,
  },
};
</script>

<style scoped>
.dashboard {
  padding: 20px;
  border-radius: 4px;
  text-align: center;
}

.queue {
  padding: 20px;
  border-radius: 4px;
  text-align: center;
}

.image_list {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  text-align: center;
  flex-wrap: wrap;
}

.image_list>div {
  margin: 10px;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  font-size: 20px;
}

.image {
  margin-bottom: 20px;
}

.error {
  color: red;
}
</style>