<template>
    <VueFinalModal class="confirm-modal" content-class="confirm-modal-content" overlay-transition="vfm-fade"
        content-transition="vfm-fade">
        <h1>{{ title }}</h1>
        <bounce-loader></bounce-loader>
        <slot>

        </slot>
        <!-- <button @click="confirm">Confirm</button> -->
        <p>Press ESC to abort</p>
    </VueFinalModal>
</template>

<script>
import { VueFinalModal } from 'vue-final-modal'
import BounceLoader from 'vue-spinner/src/BounceLoader.vue'

export default {
    props: {
        title: {
            type: String,
            default: ''
        }
    },
    methods: {
        confirm() {
            this.$emit('confirm');
        }
    },
    components: {
        VueFinalModal,
        BounceLoader
    }
}
</script>

<style>
.confirm-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
    font-family: Avenir, sans-serif;
}

.confirm-modal-content {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    background: #fff;
    border-radius: 0.5rem;
}

.confirm-modal-content>*+* {
    margin: 0.5rem 0;
}

.confirm-modal-content h1 {
    font-size: 1.375rem;
}

.confirm-modal-content button {
    margin: 0.25rem 0 0 auto;
    padding: 0 8px;
    border: 1px solid;
    border-radius: 0.5rem;
}

.dark .confirm-modal-content {
    background: #000;
}
</style>