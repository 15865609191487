/* eslint-disable */
"use strict";

import Vue from 'vue';
import axios from "axios";
import { config } from '../config';


// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = "https://tools.diamant-bilder.com/backend";
axios.defaults.baseURL = config.$apiUrl;
axios.defaults.withCredentials = true;
axios.defaults.timeout = 60 * 5000;
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

let axiosConfig = {
    baseURL: axios.defaults.baseURL,
    timeout: 60 * 5000, // Timeout
    withCredentials: true, // Check cross-site Access-Control
};

const _axios = axios.create(axiosConfig);

_axios.interceptors.request.use(
    function(config) {
        // Do something before request is sent
        return config;
    },
    function(error) {
        // Do something with request error
        return Promise.reject(error);
    }
);

// Add a response interceptor
_axios.interceptors.response.use(
    function(response) {
        // Do something with response data
        return response;
    },
    function(error) {
        // Do something with response error
        return Promise.reject(error);
    }
);

Plugin.install = function(Vue, options) {
    Vue.axios = _axios;
    window.axios = _axios;
    Object.defineProperties(Vue.prototype, {
        axios: {
            get() {
                return _axios;
            }
        },
        $axios: {
            get() {
                return _axios;
            }
        },
    });
};

export default {
    install(app) {
        app.config.globalProperties.$axios = axiosInstance;
        app.provide('axios', axiosInstance);
    }
};