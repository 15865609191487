<template>
    <div class="w-full bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-700 dark:border-gray-600">
        <div id="fullWidthTabContent" class="border-t border-gray-200 dark:border-gray-600">
            <div class="p-4 bg-white rounded-lg md:p-8 dark:bg-gray-700" id="stats" role="tabpanel"
                aria-labelledby="stats-tab">
                <dl
                    class="grid max-w-screen-xl grid-cols-2 gap-8 mx-auto text-gray-900 sm:grid-cols-3 xl:grid-cols-5 dark:text-white">

                    <source-image-count-widget apiEndpoint="process_queue" label="Images to crop" />
                    <source-image-count-widget apiEndpoint="copy_queue" label="Cropped images without copy" />
                    <source-image-count-widget apiEndpoint="shopify_queue" label="Products ready to be published" />
                    <source-image-count-widget apiEndpoint="processed" label="Published products" />
                    <a href="#">
                        <div
                            class="flex flex-col items-center p-4 justify-center border-1  border border-solid border-gray-700 hover:border-gray-600 rounded-md">
                            <dt class="mb-2 text-3xl font-extrabold">{{ dummy_product_count }}</dt>
                            <dd class="text-gray-500 dark:text-gray-400">Dummy Products</dd>
                        </div>
                    </a>
                </dl>
            </div>
        </div>
    </div>
</template>

<script>
import SourceImageCountWidget from '@/components/SourceImageCountWidget.vue';
import axios from 'axios';

export default {
    data() {
        return {
            dummy_product_count: 0,
        }
    },
    methods: {
        fetchProductCount() {
            var self = this;
            axios.get('/api/shopify/dummy-product-count/')
                .then(response => {
                    self.dummy_product_count = response.data.count;
                    console.log("Product count:", self.dummy_product_count);
                })
                .catch(error => {
                    console.error("There was an error fetching the product count:", error);
                });
        }
    },
    mounted() {

        console.log("?")
        this.fetchProductCount();
    },
    components: {
        SourceImageCountWidget
    },
}
</script>

<style scoped>
.image-count-header {
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
}

.image-count-header div {
    margin: 0 10px;
    width: 125px;
}
</style>